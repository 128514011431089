import { DeleteMS } from "@/util";

export default class TransportConfigDTO {
  constructor(item = {}) {
    this.ID = item ? item.id : null;
    this.transportType = item ? item.transport_type : null;
    this.examStartTime = item ? DeleteMS(item.exam_start_time) : '';
    this.examFinishTime = item ? DeleteMS(item.exam_finish_time) : '';
  }
}
