import ResultsExamBroadcast from '@/app/admin/modules/results/core/models/resultsExamBroadcast'
import ResultReservedExam from '@/app/admin/modules/results/core/models/resultReservedExam'
import TransportConfigDTO from "@/app/admin/modules/candidates/core/DTO/transportConfigDTO";

export default {
  data: () => ({
    examBroadcast: null,
  }),

  mounted() {
    this.initExamBroadcast();
  },

  methods: {
    async initExamBroadcast() {
      let tokens = await this.$auth.getTokensFromRepoOrRefresh();
      this.examBroadcast = new ResultsExamBroadcast(tokens.access);
      this.examBroadcast.startListen(this.examResultHandler);
    },

    examResultHandler(msg) {
      let item = this.list.find((item) => item.exam.id === msg.id);
      if (!item) return;
      let groupedItemIndex;
      if (this.groupedList) groupedItemIndex = this.groupedList[item.group].items.findIndex(_item => _item.ID === item.ID);
      if ('journey' in msg && msg.journey) {
        
        item.exam.journey = msg.journey;
        if (this.groupedList && this.groupedList[item.exam.journey.id]) this.groupedList[item.group].items[groupedItemIndex].exam.journey = msg.journey;
      }
      if('message_sent_count' in msg && 'message_sent_count' in item.exam) {
        item.exam.message_sent_count = msg.message_sent_count
      }
      if('transport_type' in msg && msg.transport_type && msg.journey) {
        item.exam.transport_type = msg.transport_type
        if (this.groupedList && this.groupedList[item.exam.journey.id]) {
          this.groupedList[item.group].items[groupedItemIndex].exam.transportationConfig = new TransportConfigDTO(msg.transportation_config);
        }
      }
      // if ('transportation_config' in msg) {
      //   item.exam.transportationConfig = new TransportConfigDTO(msg.transportation_config);
      //   if (this.groupedList && this.groupedList[item.exam.journey.id]) {
      //     this.groupedList[item.group].items[groupedItemIndex].exam.transportationConfig = new TransportConfigDTO(msg.transportation_config);
      //   }
      // }
      // if ('is_zelf_exam' in msg) {
      //   item.exam.isZelfExam = msg.is_zelf_exam;
      //   if (this.groupedList && this.groupedList[item.exam.journey.id]) this.groupedList[item.group].items[groupedItemIndex].exam.isZelfExam = msg.is_zelf_exam;
      // }
      if ('journey_was_changed' in msg) {
        item.exam.journey_was_changed = msg.journey_was_changed;
        if (msg.journey && this.groupedList && this.groupedList[item.exam.journey.id]) this.groupedList[item.group].items[groupedItemIndex].exam.journey_was_changed = msg.journey_was_changed;
      }
      //
      if (!('result' in msg && this.groupedList)) return;
      for (let field in msg) {
        if (field in this.groupedList[item.group].items[groupedItemIndex]) {
          this.groupedList[item.group].items[groupedItemIndex].result.result = new ResultReservedExam(msg.result);
        }
      }
    },
  }
};
